/* ==========================================================================
  Mixins
========================================================================== */

@mixin phone() {
  @media screen and (min-width: 480px) {
    @content;
  }
}

@mixin tablet() {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin desktop() {
  @media screen and (min-width: 1200px) {
    @content;
  }
}

@mixin body_font_bold {
  font-family: $body_font;
  font-weight: 700;
  font-style: normal;
}

@mixin body_font_bold_italic {
  font-family: $body_font;
  font-weight: 700;
  font-style: italic;
}

@mixin body_font_italic {
  font-family: $body_font;
  font-weight: 400;
  font-style: italic;
}

@mixin body_font_regular {
  font-family: $body_font;
  font-weight: 400;
  font-style: normal;
}

@mixin title_font_regular {
  font-family: $title_font;
  font-weight: 400;
  font-style: normal;
}

@mixin remove-text {
  text-indent: -99999px;
}

@mixin background-image {
  background-size: cover !important;
  background-position: center;
  background-repeat: no-repeat;
}

@mixin icon {
  @include remove-text;
  background-size: contain !important;
  background-position: center;
  background-repeat: no-repeat;
}

@mixin gradient-overlay {
  background: -moz-linear-gradient(
    top,
    rgba($color2, 0) 0%,
    rgba($color2, 1) 50%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba($color2, 0) 0%,
    rgba($color2, 1) 50%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba($color2, 0) 0%,
    rgba($color2, 1) 50%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

@mixin gradient-bg {
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    rgba($color1, 0.6) 0%,
    rgba($color2, 0) 60%
  ); /* FF3.6-15 */
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    rgba($color1, 0.6) 0%,
    rgba($color2, 0) 60%
  ); /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(
    ellipse at center,
    rgba($color1, 0.6) 0%,
    rgba($color2, 0) 60%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

@mixin vert-align {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin round-corners {
  border-radius: 3px;
}

@keyframes grow-line {
  from {
    height: 0;
  }
  to {
    height: 100px;
  }
}

.grow-line {
  -webkit-animation-name: grow-line;
  animation-name: grow-line;
}

@keyframes dash {
  to {
    stroke-dashoffset: 1000;
  }
}

@keyframes blur-bg {
  from {
    filter: blur(0);
  }
  to {
    filter: blur(10px);
  }
}
