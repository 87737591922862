/* ==========================================================================
  Customer Loyalty
========================================================================== */

.no-scroll {
  overflow: hidden;
  .fullscreen {
    animation: 0.2s linear 0s blur-bg forwards;
  }
}

.login-box {
  position: relative;
  top: 30vh;
}

.avatar {
  margin: 0 auto;
  svg {
    height: 36px;
    width: 36px;
  }
}

.avatar-sm {
  height: 36px;
  width: 36px;
}

.avatar-lg {
  height: 100px;
  width: 100px;
  svg {
    transform: scale(3);
  }
}

.header {
  position: relative;
  margin: 20px 0;
}

.back-btn {
  @include remove-text;
  position: absolute !important;
  top: 0;
  left: 35px;
  width: 60px;
  height: 100%;
  background: url('../images/nav_back.svg') no-repeat center center;
  background-size: 20%;
  z-index: 50;
}

.progress-blue {
  height: 50px;
  width: 50px;
  svg {
    width: 50px;
    height: 50px;
  }
}

.cards {
  margin: 16px 0 32px;
  p {
    margin: 10px 0;
  }
}

.card {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 16px;
  margin-bottom: 40px;
  z-index: 1;
  text-decoration: none;
  p {
    margin: 5px 0;
  }
}

.badge {
  @include round-corners;
  display: inline-block;
  padding: 3px;
  background: $color5;
  line-height: 1;
  z-index: 5;
}

.badge-overlay {
  position: absolute;
  top: 8px;
  right: 8px;
}

.badge-inline {
  position: relative;
  margin-bottom: 20px;
}

.card-inner {
  padding: 8px;
}

.card-image {
  height: 200px;
  @include background-image;
  @include round-corners;
}

.card-footer {
  position: relative;
  width: 100%;
  bottom: 0;
  background: rgba($color2, 0.4);
  z-index: 10;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vh;
  height: 100vh;
  z-index: 100;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modal {
  position: relative;
  @include round-corners;
  width: calc(100% - 62px);
  background-color: $color2;
  border: 1px solid $color1;
  padding: 20px;
  z-index: 9999;
}

.account-icons {
  position: absolute;
  display: block;
  list-style: none;
  top: -3px;
  right: 25px;
  li {
    display: inline-block;
    position: relative;
    width: 36px;
    height: 36px;
    &:first-child {
      margin-right: -10px;
    }
  }
}

.account-badge {
  position: absolute;
}

.vert-align {
  display: table-cell;
  vertical-align: middle;
}

.round-badge {
  display: table;
  border-radius: 50%;
  background: $color1;
  top: -5px;
  h1,
  h3 {
    line-height: 0.7;
    margin: 0;
  }
}

.round-badge-sm {
  height: 42px;
  width: 42px;
  h1 {
    font-size: 16px;
    letter-spacing: -1px;
  }
  h3 {
    font-size: 12px;
  }
}

.round-badge-lg {
  height: 160px;
  width: 160px;
  margin: 30px auto;
  h1 {
    font-size: 40px;
  }
  h3 {
    font-size: 26px;
  }
}

.experience-wrapper {
  position: relative;
  padding: 40px 25px;
  p {
    margin-bottom: 30px;
  }
}

.experience-image {
  height: 180px;
  width: 100%;
  object-fit: cover;
}

.spacer {
  display: block;
  height: 30px;
}

.spacer-sm {
  display: block;
  height: 10px;
}
