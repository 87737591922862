/* ==========================================================================
  Instant Win
========================================================================== */

.instant-win-loader {
  .intro-text-wrapper {
    top: -80px;
  }
}

.instant-win-bottle-img {
  height: 60vh;
  width: 60vw;
  background-size: 90% !important;
}

.instant-win-title {
  font-size: 68px;
  line-height: 1;
  text-transform: uppercase;
}

.instant-win-header {
  position: relative;
  height: 460px;
}

.instant-win-header-bg {
  position: absolute;
  top: -25vh;
  left: -25vw;
  height: 90vh;
  width: 150vw;
  background: url("../images/vineyard_stay.jpg") bottom center no-repeat;
  opacity: .7;
  clip-path: circle(260px at center);
}

.instant-win-header-bottle-img {
  position: absolute;
  height: 50vh;
  width: 40vw;
  top: 20vh;
  left: 0;
  background-size: 90% !important;
}

.column {
  position: absolute;
  right: 10vw;
  top: 18vh;
  width: 50vw;
}

.instant-win-text-box {
  position: relative;
  margin: 0 auto;
  top: 23vh;
  .instant-win-title {
    font-size: 50px;
    margin-bottom: 10px;
  }
}

.check-text-box {
  top: 25vh;
  width: 60vw;
  h2 {
    font-size: 30px;
  }
}

.lose-text-box {
  width: 90vw;
}

.win-text-box {
  width: 80vw;
}

.instant-win-form {
  margin: 40px 0 60px;
}

.instant-win-map {
  position: relative;
  width: 100%;
  margin: 40px auto;
  height: 200px;
}
