/* ==========================================================================
  Imports
========================================================================== */

@import 'variables';
@import 'mixins';
@import 'reset';
@import 'animate';
@import 'provenance';
@import 'instantWin';
@import 'customerLoyalty';

/* ==========================================================================
  Global Styles
========================================================================== */

body {
  @include body_font_regular;
  padding: 0;
  margin: 0;
  letter-spacing: normal;
  line-height: 1.3;
  font-size: 100%;
  background-color: $color2;
  color: $color3;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: 'liga';
  font-variant-numeric: lining-nums;
  -webkit-overflow-scrolling: touch;
}

::selection {
  background-color: $color3;
}

.route-wrapper {
  position: relative;
}

.route-wrapper > div {
  position: absolute;
  overflow: hidden !important;
}

.fullscreen {
  min-height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.fullwidth {
  width: 100%;
}

.fillscreen {
  min-height: 70vh;
  width: 100vw;
  overflow: hidden;
}

.gradient-bg {
  &:before {
    @include gradient-bg;
    content: '';
    position: absolute;
    top: -50vh;
    left: -50vw;
    height: 200vw;
    width: 200vw;
  }
}

.center-text {
  position: relative;
  text-align: center;
}

.footer {
  padding: 35px 20% 45px 20%;
  background: $color9;
  color: $footerColour;
  line-height: 1.5;
  .GCLLogo,
  .NestgateLogo {
    max-height: 40px;
    display: block;
    margin: 5px auto 30px auto;
  }
}

.container {
  position: relative;
  padding: 0 25px;
}

.container-sm {
  width: 60%;
  margin: 0 auto;
}

form {
  position: relative;
}

.light-bg {
  background-color: $color7;
}

.vertical-align {
  @include vert-align;
}

/* ==========================================================================
  Images
========================================================================== */

.icon {
  display: block;
  position: relative;
  @include icon;
}

.image {
  display: block;
  @include background-image;
}

.logo {
  width: 100vw;
  z-index: 10;
  background-image: url('../images/digitus_logo.svg');
}

.logo-sm {
  position: absolute;
  top: 40px;
  height: 35px;
}

.logo-md {
  height: 30px;
}

.logo-lg {
  position: absolute;
  top: 40px;
  height: 50px;
}

.bottle-img {
  background: url('../images/digitus_bottle.png') top center no-repeat;
}

.bottle-img-overlay {
  position: relative;
  // top: 15vh;
  margin: 0 auto;
  &:after {
    content: '';
    position: absolute;
    @include gradient-overlay;
    width: 100%;
    height: 100px;
    bottom: -50px;
  }
}

.intro-text-wrapper {
  @include gradient-overlay;
  display: block;
  height: 100%;
  width: 100%;
  z-index: 1;
  padding: 30px 0 20px;
}

.intro-text {
  position: relative;
  z-index: 10;
}

.page-title {
  color: $white;
  font-family: $title_font;
  font-size: 19px;
  font-weight: normal;
  font-style: normal;
  line-height: 0.75;
}

.path {
  stroke-dasharray: 100;
  animation: dash 10s linear infinite;
}

.path-long {
  stroke-dasharray: 200;
  animation: dash 5s linear infinite;
}

/* ==========================================================================
  Text
========================================================================== */

h1,
h2,
h4,
h5 {
  @include title_font_regular;
}

h1 {
  font-size: 33px;
  line-height: 0.9;
  margin: 6px auto;
}

h2 {
  font-size: 24px;
  line-height: 1;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 22px;
  line-height: 1;
}

h5 {
  font-size: 18px;
}

p,
p a {
  font-size: 18px;
}

a {
  color: $color3;
  text-decoration: underline;
}

.small-text {
  font-size: 16px;
  margin-top: 10px;
  line-height: 1.3;
}

.white-text {
  color: #fff;
}

.color1-text {
  color: $color1;
}

.color6-text {
  color: $color6;
}

.grey-text {
  color: $color4;
}

.text-box {
  position: relative;
  z-index: 10;
}

/* ==========================================================================
  Buttons
========================================================================== */

.btn {
  @include title_font_regular;
  @include round-corners;
  display: block;
  padding: 12px;
  width: 100%;
  border: 0;
  font-size: 20px;
  margin-top: 20px;
  text-decoration: none;
  cursor: pointer;
}

.btn-primary {
  background-color: $color5;
  color: #fff;
}

button[disabled] {
  opacity: 0.5;
}

/* ==========================================================================
  Forms
========================================================================== */

.react-code-input {
  margin: 20px auto;
  input {
    @include round-corners;
    font-family: $body_font !important;
    width: 40px !important;
    height: 65px !important;
    font-size: 32px !important;
    text-align: center;
    padding: 0 !important;
    margin: 0 14px 0 0 !important;
    text-transform: uppercase;
    &:last-child {
      margin-right: 0 !important;
    }
  }
}

.error {
  margin: 0 0 20px 0;
  color: $color5;
}

.field {
  @include body_font_regular;
  @include round-corners;
  width: calc(100% - 20px);
  padding: 10px;
  margin: 20px 0;
  border: none;
}

/* ==========================================================================
  Homepage
========================================================================== */

.nav-link {
  @include title_font_regular;
  display: block;
  margin: 60px 0;
  font-size: 30px;
  text-decoration: none;
  text-align: center;
}

/* ==========================================================================
  Loading
========================================================================== */

.progress {
  height: 24px;
  width: 24px;
  svg {
    width: 26px;
    height: 26px;
  }
}

.progress-sm {
  margin: 8% auto 0;
}

.progress-md {
  margin: 15% auto 0;
  svg {
    transform: scale(1.2);
  }
}

.progress-lg {
  margin: 15% auto 0;
  svg {
    transform: scale(2);
  }
}

/* ==========================================================================
  Navigation
========================================================================== */

.menu-btn {
  @include remove-text;
  position: absolute !important;
  top: 0;
  width: 60px;
  height: 70px;
  background-size: 35%;
  z-index: 101;
  &.menu-open {
    background: url('../images/nav_burger.svg') no-repeat center center;
    &.with-points {
      &:after {
        content: '';
        width: 12px;
        height: 12px;
        background-color: $color5;
        display: inline-block;
        position: absolute;
        border-radius: 50%;
        top: 20px;
        right: 12px;
      }
    }
  }

  &.menu-closed {
    background: url('../images/nav_x.svg') no-repeat center center;
  }
}

.menu-panel {
  background-color: $color2;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  transition: left 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  ul {
    padding: 120px 22px;
    li {
      position: relative;
      display: inline-block;
      margin-bottom: 60px;
      list-style: none;
      padding-bottom: 10px;
      border-bottom: 3px solid rgba($color10, 0);
      .menu-link {
        display: block;
        font-family: $title_font;
        font-size: 40px;
        font-weight: normal;
        font-style: normal;
        line-height: 0.75;
        letter-spacing: normal;
        color: #ffffff;
        margin-bottom: 5px;
        text-transform: uppercase;
        text-decoration: none;
        &.points-available:after {
          content: '';
          width: 16px;
          height: 16px;
          background-color: $color5;
          display: inline-block;
          position: absolute;
          border-radius: 50%;
          top: -12px;
          right: -20px;
        }
      }
      .menu-link-points {
        display: inline-block;
        font-family: $title_font;
        font-size: 19px;
        font-weight: normal;
        font-style: normal;
        line-height: 0.75;
        letter-spacing: normal;
        color: #ffffff;
        background-color: $color5;
        padding: 2px 4px;
        border-radius: 3px;
      }
      &.menu-link-active {
        border-color: $color10;
      }
      opacity: 0;
      left: -50px;
      transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
      &:first-of-type {
        transition-delay: 0.5s;
      }
      &:last-of-type {
        transition-delay: 0.7s;
      }
    }
  }
  &.menu-panel-open {
    left: 0;
    ul li {
      opacity: 1;
      left: 0;
    }
  }
  &.menu-panel-closed {
    left: -100%;
    transition-delay: 0.3s;
    ul li {
      opacity: 0;
      left: -50px;
      &:first-of-type {
        transition-delay: 0.1s;
      }
      &:last-of-type {
        transition-delay: 0.2s;
      }
    }
  }
}
