/* ==========================================================================
  Variables
========================================================================== */

@font-face {
  font-family: 'optician_sansregular';
  src: url('../fonts/optician-sans-webfont.woff2') format('woff2'),
    url('../fonts/optician-sans-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

$body_font: 'linotype-sabon', sans-serif;
$title_font: 'optician_sansregular', sans-serif;

/* ==========================================================================
  Colours
========================================================================== */

$color1: #476e7d;
$color2: #0a0f11;
$color3: #9dc8da;
$color4: #d6dde1;
$color5: #7a1316;
$color6: #2a414b;
$color7: #142329;
$color8: #344a54;
$color9: #1d1d1b;
$color10: #172329;
$white: #ffffff;
$footerColour: #4e6d7c;
