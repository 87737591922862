/* ==========================================================================
  Provenance
========================================================================== */

.react-reveal:last-of-type {
  padding-bottom: 50px;
}

.provenance-loader {
  display: flex;
  align-items: center;
  max-width: 720px;
  margin: 0 auto;
  &.gradient-bg:before {
    @include tablet {
      top: -90vh;
    }
    @include desktop {
      top: -250vh;
    }
  }
  .intro-text-wrapper {
    width: 60%;
    text-align: left;
    background: none;
    ul {
      padding: 30px 0;
      li {
        display: block;
        display: flex;
        margin-bottom: 10px;
        .icon {
          margin-right: 14px;
        }
      }
    }
  }
  .provenance-bottle-img {
    height: 50vh;
    width: 40%;
    max-width: 140px;
    background-size: 135% !important;
    @include tablet {
      max-width: 180px;
    }
  }
}

.timeline-item-wrapper {
  position: relative;
  display: block;
  padding-top: 120px;
}

.inview {
  .timeline-line {
    display: block;
    position: absolute;
    top: 0;
    left: calc(50% - 1px);
    border-left: 1px solid rgba($color1, 0.5);
    height: 0;
    width: 1px;
    animation: 0.6s linear 0s grow-line forwards;
    &.first-line:before,
    &.last-line:before {
      content: '';
      position: absolute;
      top: 0;
      left: -9px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      border: solid 1px $color8;
      background-color: $color2;
    }
    &.last-line:before {
      top: auto;
      bottom: 0;
    }
  }
}

// .timeline-item-block {
//   @include gradient-bg;
// }

.timeline-image {
  width: 80%;
  max-width: 320px;
  display: block;
  margin: 10px auto 40px;
}

.timeline-details {
  margin: 20px 30px;
  .large-icon:first-of-type {
    margin-top: 30px;
  }
}

.timeline-detail {
  margin: 10px 0 20px;
  display: flex;
}

.timeline-icon {
  display: inline-block !important;
  height: 24px;
  width: 24px;
  margin-right: 30px;
}

.timeline-label {
  width: 70%;
}

.timeline-date {
  position: absolute;
  right: 30px;
}

.timeline-map {
  position: relative;
  width: calc(100vw - 60px);
  height: 200px;
  margin: 20px 0;
}

.large-icon {
  margin: 10px 0 10px 25%;
  width: 70%;
  .timeline-label {
    color: #fff;
  }
}

.winery {
  display: block;
  width: 60%;
  margin: 0 auto;
  .timeline-date {
    position: relative;
    right: 0;
    left: 56px;
  }
  .timeline-icon {
    top: 12px;
  }
  .timeline-label {
    display: inline-block;
  }
}

.calendar-icon {
  background-image: url('../images/calendar.svg');
}

.pin-icon {
  background-image: url('../images/pin.svg');
}

.bottles-icon {
  background-image: url('../images/bottles.svg');
}

.cherry-icon {
  background-image: url('../images/cherry.svg');
}

.cedar-icon {
  background-image: url('../images/cedar.svg');
}

.cinammon-icon {
  background-image: url('../images/cinnamon.svg');
}
